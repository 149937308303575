import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CartItems } from '../';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../../../redux/actions/cart';
import { useBranches } from '../../../hooks';

import { isIosApp } from '../../../services/ios';

import './Cart.scss';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

export default function Cart({ visible, toggle }) {
    const lang = useSelector((state) => state.lang);
    const cart = useSelector((state) => state.cart);

    const dispatch = useDispatch();

    const { branches, loading } = useBranches();
    const closed = !loading && branches.every((b) => !b.open) && branches.every((b) => !b.delivering);

    function removeItem(index) {
        dispatch(removeFromCart(index));
    }

    useEffect(() => {
        // Disable body scroll side cart is open
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="cart-container">
            <div className="cart-modal">
                <CartItems cart={cart} lang={lang} removeItem={removeItem} />
                <div className="uppsellBtn" onClick={toggle}>
                    {!cart.find((c) => c.category?.name['en'] == 'Dips') ? (
                        <Link to={'/dips'}>
                            <button>{lang === 'is' ? 'Vantar ekki sósu lika?' : 'Are you missing a dip?'}</button>
                        </Link>
                    ) : !cart.find((c) => c.category?.name['en'] == 'Drinks') ? (
                        <Link to={'/drinks'}>
                            <button>
                                {lang === 'is' ? 'Ekki gleyma að drekka með!' : "Don't forget the drinks"}
                                {/* <img src="https://res.cloudinary.com/dgtjcglli/w_550/menu/609d3c38a73f1b0017449ac3_0.png" /> */}
                            </button>
                        </Link>
                    ) : (
                        ''
                    )}
                </div>
                <div className="checkoutBtn">
                    <a
                        href={
                            isIosApp()
                                ? `https://castello.netlify.app/checkout?cart=${JSON.stringify(cart)}`
                                : `${closed ? '' : '/checkout'}`
                        }
                        onClick={(e) => {
                            if (isIosApp()) {
                                e.preventDefault();
                                window.open(
                                    `https://castello.netlify.app/checkout?cart=${JSON.stringify(cart)}`,
                                    '_blank'
                                );
                            }
                        }}
                    >
                        <button className="cart-button">
                            <h2 className="panta">
                                {closed ? (lang === 'is' ? 'Lokað' : 'Closed') : lang === 'is' ? 'Panta' : 'Order'}
                            </h2>
                            <p className="cta-subtitle">
                                {formatISK(
                                    cart.reduce(
                                        (curr, acc) =>
                                            (acc.pickupPrice
                                                ? Math.min(acc.pickupPrice, acc.deliveryPrice)
                                                : acc.price) + curr,
                                        0
                                    )
                                )}{' '}
                                kr.
                            </p>
                        </button>
                    </a>
                </div>
            </div>
            <div className="cart-background" onClick={toggle} />
        </div>
    );
}

//https://ios.castello.is/checkout?cart=${JSON.stringify(cart)}
